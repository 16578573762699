import React,{useState,useEffect} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Container, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import API from "../../utils/API";
import Loader from "../../components/Loader";
import {
    User as UserIcon,
    MapPin,
    Mail,
    Phone, Edit, Edit2, CheckCircle
} from "react-feather";
import {faFax, faPlus, faRecycle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LenderStatus from "../../components/status/LenderStatus";
import {Link, useHistory} from "react-router-dom";
import LenderOrders from "./LenderOrders";
import classnames from "classnames";
import LenderSettings from "./LenderSettings";
import UserForm from "./UserForm";
import Users from "./Users";
import User from "../../components/fields/User";
import Filters from "./Filters";
import BordeloConnect from "../bordelo/lender/BordeloConnect";
import BillingType from "../../components/fields/BillingType";
import UserAssignForm from "./UserAssignForm";
import Memo from "../../components/memo/Memo";
import SystemLog from "../../components/SystemLog";
import LenderOneToOne from "./LenderOneToOne";

const Lender = (props) => {
    const [lender,setLender] = useState(null)

    let history = useHistory();

    const [activeTab,setActiveTab] = useState("orders")

    const [userForm,setUserForm] = useState(false)
    const [userFormId,setUserFormId] = useState(null)
    const [defaultSendformat, setDefaultSendFormat] = useState(0)
    const [modal, setModal] = useState(false)

    const [reload,setReload] = useState(false)

    const toggleReload = () => {
        setReload(!reload);
    }

    useEffect(() => {
        API.get('/admin/lender/'+props.match.params.id).then((response) => {
            setLender(response.data.data)
        });
        API.get('/admin/order/lender/' + props.match.params.id).then((response) => {
            const orderList = response.data.data;
            orderList.forEach((order) => {
                if(order.sendconfig !== null && order.config !== null) {
                    setDefaultSendFormat(order.id)
                    return
                }
            });
        });
    }, [props.match.params.id,reload])

    const editLender = (lenderId) => {
        history.push("/lender/form/"+lenderId)
    }

    const newChannel = (lenderId, value) => {
        if(defaultSendformat > 0) {
            setModal(true)
            if(value && value == "true") {
                history.push({
                    pathname: "/lender/order/form/"+lenderId,
                    state: defaultSendformat
                })
            }
            if(value && value == "false") {
                history.push("/lender/order/form/"+lenderId)
            }
        }else {
            history.push("/lender/order/form/"+lenderId)
        }
    }

    const editUser = (userId) => {
        setUserFormId(userId)
        setUserForm(true)
    }

    useEffect(()=>{
        if (!userForm) {
            setUserFormId(null)
        }
    },[userForm])

    return (
        <Container fluid className="p-0">
            <Modal isOpen={modal} toggle={() => setModal(!modal)} >
                <ModalHeader toggle={() => setModal(!modal)}>Copy Default Sendformat</ModalHeader>
                <ModalBody>
                    Would you like to copy the default send format (order #{defaultSendformat}) to this new order?<br/><br/><strong>It copies only the send format and configuration. You need to set the price and filters manually.</strong>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => newChannel(lender.id, e.target.value)} value="true">
                        Yes
                    </Button>
                    <Button color="secondary" onClick={(e) => newChannel(lender.id, e.target.value)} value="false">
                        No
                    </Button>
                </ModalFooter>
            </Modal>
            {lender ?
                <>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/lender/list">Lenders</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>{lender.title}</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <h1 className="h3 mb-3">{lender.title}</h1>
                                    <h4 className="h4 mb-3">Id# {lender.id}</h4>
                                    <div className="card-actions float-right">
                                        <Edit2 onClick={() => {editLender(lender.id)}} className="align-middle mr-1 cursor-pointer" size={12} />
                                    </div>
                                    <LenderStatus status={lender.status}/>
                                    <ul className="list-unstyled mb-0">
                                        {lender.invoicemethod &&
                                            <li className="mb-1">
                                                Invoice method: <strong>{lender.invoicemethod}</strong>
                                            </li>
                                        }
                                        {lender.billingtype &&
                                            <li className="mb-1">
                                                Billing type: <strong><BillingType billingType={lender.billingtype}/></strong>
                                            </li>
                                        }
                                        {lender.userrep &&
                                            <li className="mb-1">
                                                Account representative: <strong><User user={lender.userrep}/></strong>
                                            </li>
                                        }
                                        {(lender.address || lender.city || lender.state || lender.zip) &&
                                        <li className="mb-1">
                                            <MapPin width={14} height={14}
                                                    className="mr-1"/> {lender.address} {lender.city} {lender.state} {lender.zip}
                                        </li>
                                        }
                                        {(lender.firstname || lender.lastname) &&
                                        <li className="mb-1">
                                            <UserIcon width={14} height={14}
                                                  className="mr-1"/> {lender.firstname} {lender.lastname}
                                        </li>
                                        }
                                        {lender.email &&
                                        <li className="mb-1">
                                            <Mail width={14} height={14} className="mr-1"/> {lender.email}
                                        </li>
                                        }
                                        {lender.phone &&
                                        <li className="mb-1">
                                            <Phone width={14} height={14} className="mr-1"/> {lender.phone}
                                        </li>
                                        }
                                        {(lender.returnpolicy && lender.returnpolicy*1>0) ?
                                            <li className="mb-1">
                                                Return policy: <strong>{lender.returnpolicy} days</strong>
                                            </li>
                                            :
                                            <li className="mb-1">
                                                Return policy: <strong className="text-warning">No returns</strong>
                                            </li>
                                        }
                                        {lender.fax &&
                                            <li className="mb-1">
                                                <FontAwesomeIcon className="align-middle mr-1" icon={faFax}
                                                                 fixedWidth/> {lender.fax}
                                            </li>
                                        }
                                        {lender.emailcopy === "1" &&
                                            <li className="mb-1">
                                                Email delivery copy:<br/>
                                                <strong className="text-black">
                                                    {(lender.emailcopydestination && lender.emailcopydestination.length > 0) ?
                                                        lender.emailcopydestination
                                                        :
                                                        lender.email
                                                    }
                                                </strong>
                                            </li>
                                        }
                                    </ul>
                                    <Memo typeId={lender.id} type={'lender'}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <div className={"tab default"}>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "orders" })}
                                            onClick={() => {
                                                setActiveTab("orders");
                                            }}
                                        >
                                            <strong>Orders</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "settings" })}
                                            onClick={() => {
                                                setActiveTab("settings");
                                            }}
                                        >
                                            <strong>Settings</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "filters" })}
                                            onClick={() => {
                                                setActiveTab("filters");
                                            }}
                                        >
                                            <strong>Filters</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "users" })}
                                            onClick={() => {
                                                setActiveTab("users");
                                            }}
                                        >
                                            <strong>Users</strong>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "log" })}
                                            onClick={() => {
                                                setActiveTab("log");
                                            }}
                                        >
                                            <strong>Log</strong>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="orders" style={{minHeight:"30px"}}>
                                        <Button color="primary" onClick={() => {newChannel(lender.id)}} className="float-right mt-n1 mb-1">
                                            <FontAwesomeIcon icon={faPlus}/> New order
                                        </Button>
                                        <LenderOrders lenderId={lender.id}/>
                                    </TabPane>
                                    <TabPane tabId="onetoone">
                                        <LenderOneToOne lenderId={lender.id} reload={toggleReload}/>
                                    </TabPane>
                                    <TabPane tabId="settings">
                                        <LenderSettings lenderId={lender.id}/>
                                    </TabPane>
                                    <TabPane tabId="filters">
                                        <Filters lender={lender}/>
                                    </TabPane>
                                    <TabPane tabId="users" style={{minHeight:"30px"}}>
                                        {userForm ?
                                            <button onClick={() => {
                                                setUserForm(false)
                                            }} className="float-right btn btn-outline-secondary mb-2">X</button>
                                            :
                                            <>
                                                <Button color="warning" onClick={() => {
                                                    setUserForm(2)
                                                }} className="float-right mt-n1 ml-1">
                                                    <FontAwesomeIcon icon={faRecycle}/> Assign existing user
                                                </Button>
                                                <Button color="primary" onClick={() => {
                                                    setUserForm(1)
                                                }} className="float-right mt-n1">
                                                    <FontAwesomeIcon icon={faPlus}/> New user
                                                </Button>
                                            </>
                                        }
                                        {userForm ?
                                            <>
                                                {userForm === 1 ?
                                                    <UserForm closeForm={setUserForm} lenderId={lender.id} id={userFormId}/>
                                                    :
                                                    <UserAssignForm closeForm={setUserForm} lenderId={lender.id}/>
                                                }
                                            </>
                                            :
                                            <Users lenderId={lender.id} startEditUser={editUser}/>
                                        }
                                    </TabPane>
                                    <TabPane tabId="bordelo">
                                        <BordeloConnect id={lender.id}/>
                                    </TabPane>
                                    <TabPane tabId="log">
                                        <SystemLog lender={[lender.id]}/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </>
                :
                <Loader/>
            }
        </Container>
    )
}

export default Lender
